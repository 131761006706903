import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Lead } from "../../models/diagnostic/diagnosticSearch";

export const getLead = createAsyncThunk(
  //action type string
  "download/getLead",
  // callback function
  async (email: string) => {
    let dataLeads = [] as Lead[];
    const params = {
      page: 0,
      size: 100,
    };
    try {
      let response = await api.get(`/leads/?email=${email}`, { params });
      let data = response.data;
      dataLeads = [...data.items];
      while (data.next_page) {
        params.page = data.next_page;
        response = await api.get(`/leads/?email=${email}`, { params });
        data = response.data; // Procesar los datos de la siguiente página aquí
        dataLeads.push(...data.items);
      }
      return dataLeads;
    } catch (error: any) {
      return 404;
    }
  }
);

export const getFrontierIdByLeadId = createAsyncThunk(
  //action type string
  "download/getFrontierId",
  // callback function
  async (leadId: string) => {
    const { data } = await api.get(
      `/assignees/frontiers?lead_id=${leadId}&page=0&size=50`
    );
    return data.items[0]?.id;
  }
);

export const getDiagnosticByFrontierId = createAsyncThunk(
  //action type string
  "download/getDiagnostics",
  // callback function
  async (frontierId: string) => {
    const { data } = await api.get(
      `/documentation/technical-business-concept?frontier_id=${frontierId}&sorted_by_create_at=false&page=0&size=50`
    );

    return data;
  }
);
