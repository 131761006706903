/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-ignore
import { configureStore } from "@reduxjs/toolkit";
import leadReducer from "../features/diagnosticCompliance/diagnosticComplianceSlice";
import pendingReducer from "../features/pendingDiagnostic/pendingDiagnosticSlice";
import downloadReducer from "../features/downloadDiagnostic/downloadDiagnosticSlice";
import tableAddress from "../features/table-address/tableAddress";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";

export const store = configureStore({
  reducer: {
    leads: leadReducer,
    pending: pendingReducer,
    download: downloadReducer,
    table: tableAddress,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector