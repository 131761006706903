/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from "@enerbit/base";

export const getWiresCompliances = async (data: any) => {
  const response = [];
  if (
    data.field.input_data.form_data?.electrical_conductors?.voltage_signal?.material &&
    data.field.input_data.form_data?.electrical_conductors?.voltage_signal?.gauge?.value
  ) {
    const voltageSignal = await api.get(
      "/inventory-documentation/wires-compliances-certificate?page=0&size=50",
      {
        params: {
          material:
            data.field.input_data?.form_data?.electrical_conductors?.voltage_signal?.material,
          gauge:
            data.field.input_data?.form_data?.electrical_conductors?.voltage_signal.gauge?.value,
        },
      }
    );
    response.push({
      fileName: "Certificado de conformidad producto cableado: Voltaje",
      exists: voltageSignal.data.items.length > 0,
    });
  }
  if (
    data.field.input_data.form_data?.electrical_conductors?.current_signal?.material &&
    data.field.input_data.form_data?.electrical_conductors?.current_signal?.gauge?.value
  ) {
    const currentSignal = await api.get(
      "/inventory-documentation/wires-compliances-certificate?page=0&size=50",
      {
        params: {
          material:
            data.field.input_data.form_data?.electrical_conductors?.current_signal?.material,
          gauge:
            data.field.input_data.form_data?.electrical_conductors?.current_signal?.gauge?.value,
        },
      }
    );
    response.push({
      fileName: "Certificado de conformidad producto cableado: Corriente",
      exists: currentSignal.data.items.length > 0,
    });
  }

  return response;
};

export const getBornersCompliance = async (data: any) => {
  const bornersObj = data.field.input_data.form_data?.terminals;

  if (bornersObj && bornersObj.length > 0) {
    const tempDocs = bornersObj.map(async (item: any, index: number) => {
      return {
        fileName: `Certificado de conformidad producto bornera de pruebas n° ${index + 1}`,
        docs: await api.get(
          "/inventory-documentation/borners-compliance-certificate?page=0&size=50",
          { params: { brand: item.manufacturer } }
        ),
      };
    });
    const docs = await Promise.all(tempDocs);
    return docs.map((item) => {
      return { fileName: item.fileName, exists: item.docs.data.items.length > 0 };
    });
  }

  return [];
};

export const getTTCompliances = async (data: any) => {
  const response = [];
  if (data.field.input_data.form_data?.voltage_transformer?.r_phase?.manufacturer) {
    const phaseR = await api.get(
      "/inventory-documentation/tt-compliances-certificate?page=0&size=50",
      {
        params: {
          brand: data.field.input_data.form_data?.voltage_transformer?.r_phase?.manufacturer,
        },
      }
    );
    response.push({
      fileName: "Certificado conformidad producto transformadores de tensión: Fase R",
      exists: phaseR.data.items.length > 0,
    });
  }

  if (data.field.input_data.form_data?.voltage_transformer?.s_phase?.manufacturer) {
    const phaseS = await api.get(
      "/inventory-documentation/tt-compliances-certificate?page=0&size=50",
      {
        params: {
          brand: data.field.input_data.form_data?.voltage_transformer?.s_phase?.manufacturer,
        },
      }
    );

    response.push({
      fileName: "Certificado conformidad producto transformadores de tensión: Fase S",
      exists: phaseS.data.items.length > 0,
    });
  }

  if (data.field.input_data.form_data?.voltage_transformer?.t_phase?.manufacturer) {
    const phaseT = await api.get(
      "/inventory-documentation/tt-compliances-certificate?page=0&size=50",
      {
        params: {
          brand: data.field.input_data.form_data?.voltage_transformer?.t_phase?.manufacturer,
        },
      }
    );

    response.push({
      fileName: "Certificado conformidad producto transformadores de tensión: Fase T",
      exists: phaseT.data.items.length > 0,
    });
  }

  return response;
};

export const getTCCompliances = async (data: any) => {
  const response = [];
  if (data.field.input_data.form_data?.voltage_transformer?.r_phase?.manufacturer) {
    const phaseR = await api.get(
      "/inventory-documentation/tc-compliances-certificate?page=0&size=50",
      {
        params: {
          brand: data.field.input_data.form_data?.voltage_transformer?.r_phase?.manufacturer,
        },
      }
    );
    response.push({
      fileName: "Certificado conformidad producto transformadores de corriente: Fase R",
      exists: phaseR.data.items.length > 0,
    });
  }

  if (data.field.input_data.form_data?.voltage_transformer?.s_phase?.manufacturer) {
    const phaseS = await api.get(
      "/inventory-documentation/tc-compliances-certificate?page=0&size=50",
      {
        params: {
          brand: data.field.input_data.form_data?.voltage_transformer?.s_phase?.manufacturer,
        },
      }
    );

    response.push({
      fileName: "Certificado conformidad producto transformadores de corriente: Fase S",
      exists: phaseS.data.items.length > 0,
    });
  }

  if (data.field.input_data.form_data?.voltage_transformer?.t_phase?.manufacturer) {
    const phaseT = await api.get(
      "/inventory-documentation/tc-compliances-certificate?page=0&size=50",
      {
        params: {
          brand: data.field.input_data.form_data?.voltage_transformer?.t_phase?.manufacturer,
        },
      }
    );

    response.push({
      fileName: "Certificado conformidad producto transformadores de corriente: Fase T",
      exists: phaseT.data.items.length > 0,
    });
  }

  return response;
};

export const getTCCalibration = async (data: any) => {
  const response = [];
  if (data.field.input_data.form_data?.current_transformer?.r_phase?.serial_number?.value) {
    const phaseR = await api.get(
      "/inventory-documentation/tc-calibrations-certificate?page=0&size=50",
      {
        params: {
          number:
            data.field.input_data.form_data?.current_transformer?.r_phase?.serial_number?.value,
        },
      }
    );
    response.push({
      fileName: "Certificado calibración transformadores de corriente: Fase R",
      exists: phaseR.data.items.length > 0,
    });
  }

  if (data.field.input_data.form_data?.current_transformer?.s_phase?.serial_number?.value) {
    const phaseS = await api.get(
      "/inventory-documentation/tc-calibrations-certificate?page=0&size=50",
      {
        params: {
          number:
            data.field.input_data.form_data?.current_transformer?.s_phase?.serial_number?.value,
        },
      }
    );

    response.push({
      fileName: "Certificado calibración transformadores de corriente: Fase S",
      exists: phaseS.data.items.length > 0,
    });
  }

  if (data.field.input_data.form_data?.current_transformer?.t_phase?.serial_number?.value) {
    const phaseT = await api.get(
      "/inventory-documentation/tc-calibrations-certificate?page=0&size=50",
      {
        params: {
          number:
            data.field.input_data.form_data?.current_transformer?.t_phase?.serial_number?.value,
        },
      }
    );

    response.push({
      fileName: "Certificado calibración transformadores de corriente: Fase T",
      exists: phaseT.data.items.length > 0,
    });
  }

  return response;
};

export const getTTCalibration = async (data: any) => {
  const response = [];

  if (data.field.input_data.form_data?.voltage_transformer?.r_phase?.serial_number?.value) {
    const phaseR = await api.get(
      "/inventory-documentation/tt-calibrations-certificate?page=0&size=50",
      {
        params: {
          number:
            data.field.input_data.form_data?.voltage_transformer?.r_phase?.serial_number?.value,
        },
      }
    );
    response.push({
      fileName: "Certificado calibración transformadores de tensión: Fase R",
      exists: phaseR.data.items.length > 0,
    });
  }

  if (data.field.input_data.form_data?.voltage_transformer?.s_phase?.serial_number?.value) {
    const phaseS = await api.get(
      "/inventory-documentation/tt-calibrations-certificate?page=0&size=50",
      {
        params: {
          number:
            data.field.input_data.form_data?.voltage_transformer?.s_phase?.serial_number?.value,
        },
      }
    );

    response.push({
      fileName: "Certificado calibración transformadores de tensión: Fase S",
      exists: phaseS.data.items.length > 0,
    });
  }

  if (data.field.input_data.form_data?.voltage_transformer?.t_phase?.serial_number?.value) {
    const phaseT = await api.get(
      "/inventory-documentation/tt-calibrations-certificate?page=0&size=50",
      {
        params: {
          number:
            data.field.input_data.form_data?.voltage_transformer?.t_phase?.serial_number?.value,
        },
      }
    );

    response.push({
      fileName: "Certificado calibración transformadores de tensión: Fase T",
      exists: phaseT.data.items.length > 0,
    });
  }

  return response;
};

export const getCellsCompilance = async (data: any) => {
  const response = [];
  if (data.field.input_data?.form_data?.electrical_boxes) {
    const cells = await api.get(
      "/inventory-documentation/cells-compliance-certificate?page=0&size=50",
      {
        params: { brand: data.field.input_data?.form_data?.electrical_boxes[0].manufacturer.value },
      }
    );
    response.push({
      fileName: "Certificado de conformidad producto caja o gabinete",
      exists: cells.data.items.length > 0,
    });
  }

  return response;
};
