import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getPendingDiagnostic = createAsyncThunk(
  //action type string
  "pending/getPendingDiagnostic",
  // callback function
  async () => {
    const { data } = await api.get(
      `/frontier-initial-diagnostic/pending-diagnostics?page=0&size=20`
    );

    return data;
  }
);
