import { CommentsState } from "../../../models/diagnostic/diagnosticComments";
import { InitialInspectionSchema } from "../../../models/diagnostic/diagnosticInitialInspection";
import {
  ItemAddMaterial,
  MaterialType,
} from "../../../models/diagnostic/diagnosticMaterials";

export const getMaterialMo = (
  data: InitialInspectionSchema
): ItemAddMaterial => {
  const state: MaterialType = {
    materials: [],
  };

  data.form_data.materials !== null && data.form_data.materials?.length
    ? (state.materials = [...state.materials, ...data.form_data.materials])
    : null;

  data.form_data.materials_to_execute !== null &&
  data.form_data.materials_to_execute?.length
    ? (state.materials = [
        ...state.materials,
        ...data.form_data.materials_to_execute,
      ])
    : null;

  data.form_data.activities_to_execute !== null &&
  data.form_data.activities_to_execute?.length
    ? (state.materials = [
        ...state.materials,
        ...data.form_data.activities_to_execute,
      ])
    : null;

  if (!state.materials.length) {
    return { ...state, total_price: "$ " };
  }
  const price = handlePrice(state);
  return { ...state, total_price: price };
};

export const handlePrice = (data: MaterialType): string => {
  const price: number = getTotalPrice(data);
  const formattedPrice: string = formatoPesos(price);
  return formattedPrice;
};

export const getMaterialsAndLabor = (data: CommentsState) => {
  return {
    measurement_type: data.measurementType,
    main_meter: data.mainMeter,
    optional_meter: data.optionalMeter,
    test_block: data.testBloque,
    comunication: data.comunication,
    current_transformer: data.currentTransformer,
    voltage_transformer: data.voltageTransformer,
    box: data.box,
    control_wire: data.controlWire,
    other: data.other,
    comment_or: data.comment_or,
    observation_work_order: data.observation_work_order,
    opening: data.opening,
    status: data.viability,
    initial_inspection_id: data.initial_inspection_id,
    of_type: data.of_type,
  };
};

export function getTotalPrice(data: MaterialType): number {
  if (data === null) return 0;

  let totalPrices = 0;

  for (let i = 0; i < data.materials.length; i++) {
    let price = data.materials[i].charge;
    price = String(price);

    if (!price.length) continue;

    const cantidad = data.materials[i]?.quantity;

    let quantity: number;

    if (cantidad !== undefined) {
      quantity = Number(cantidad);
    } else {
      quantity = 1;
    }

    const charge = Number(price);

    const individualPrice: number = Math.ceil(charge) * quantity;
    totalPrices += individualPrice;
  }

  return Number(totalPrices);
}

export const formatoPesos = (number: number) => {
  const exp = /(\d)(?=(\d{3})+(?!\d))/g;
  const rep = "$1.";
  return number.toString().replace(exp, rep);
};

export function capitalizer(word: string) {
  const textTolowerCase = word.toLowerCase();
  console.log(textTolowerCase)
  const firstWord = textTolowerCase ? textTolowerCase[0].toUpperCase() : "";
  const othersWords = textTolowerCase.slice(1);

  return firstWord + othersWords;
}
