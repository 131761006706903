/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getPendingDiagnostic } from "./thunks";
import { Pending, PendingState } from "../../models/Pending/pending";

const initialState: PendingState = {
  pendingArray: [] as Pending[],
  isLoading: false,
  textAlert: "",
  severityAlert: "",
  page: 1,
  size: 5,
};

export const pendingSlice = createSlice({
  name: "pending",
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.size = action.payload;
    },
  },
  extraReducers: {
    //@ts-ignore
    [getPendingDiagnostic.pending]: (state) => {
      state.isLoading = true;
      state.textAlert = "";
      state.severityAlert = ""; // state.isRequestingSignIn = true;
    },
    //@ts-ignore
    [getPendingDiagnostic.fulfilled]: (
      state: any,
      { payload }: PayloadAction<Pending[]>
    ) => {
      const updatedList = payload.map((item, index) => {
        return {
          ...item,
          id: index,
          of_type: item.of_type === "desktop" ? "Escritorio" : "Campo",
        };
      });
      state.pendingArray = updatedList;
      state.isLoading = false;
    },
    //@ts-ignore
    [getPendingDiagnostic.rejected]: (state) => {
      (state.textAlert = "Ha ocurrido un error"),
        (state.severityAlert = "error"),
        (state.isLoading = false);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPage, setPageSize } = pendingSlice.actions;
export const pendingArray = (state: PendingState) => state.pendingArray;
export const isLoading = (state: PendingState) => state.isLoading;

export default pendingSlice.reducer;
