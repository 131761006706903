import { store } from "./store/store";
import { Provider } from "react-redux";
//@ts-ignore
import { ThemeConfig, PrivateRoute, initEnviroment } from "@enerbit/base";
import RecordsContainer from "./pages/RecordsContainer/RecordsContainerPage";
import { hot } from 'react-hot-loader/root';
import "./App.css";

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
  // @ts-ignore
  firebaseVapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
});

export default hot(function Root() {
  return (
    <PrivateRoute>
      <Provider store={store}>
        <ThemeConfig>
          <RecordsContainer />
        </ThemeConfig>
      </Provider>
    </PrivateRoute>
  );
});

