/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint no-use-before-define: 2 */ // --> ON

import { InitialInspectionSchema } from "../../models/diagnostic/diagnosticInitialInspection";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  diagnosticState,
  EmitedBy,
  Responsible,
} from "../../models/diagnostic/diagnostic";

import { StateStorage } from "../../models/diagnostic/diagnosticStorage";
import { RecordsPhotographics } from "../../models/diagnostic/diagnosticRecordsPhotographic";
import { ViabilyCheckingSchema } from "../../models/diagnostic/diagnosticViabilityChecking";
import { Lead } from "../../models/diagnostic/diagnosticSearch";
import {
  CommentsState,
  UrlPathsOfRender,
} from "../../models/diagnostic/diagnosticComments";
import { ItemAddMaterial } from "../../models/diagnostic/diagnosticMaterials";
import { getForntierIdByLead, getOutputData } from "./thunks";

const initialState: diagnosticState = {
  lead: {} as Lead,
  totalMaMo: {} as ItemAddMaterial,
  materials: {},
  diagnosisIssued: {} as CommentsState,
  initialInspection: null,
  initialInspectionPhotos: {} as RecordsPhotographics,
  checkingInitialInspection: [] as ViabilyCheckingSchema,
  urlPathsRender: {} as UrlPathsOfRender,
  hasError: false,
  errorMessage: "",
  email: "",
  leadId: "",
  frontierId: "",
  fieldServiceCertificationId: "",
  desktop: null,
  isLoading: false,
  previousDiagnostic: null,
  emitedBy: {} as EmitedBy,
  responsiblePreviousDiagnostic: {} as Responsible,
  documents: [],
  loadingDocs: false,
  docsError: false,
};

export const diagnosticSlice: any = createSlice({
  name: "leads",
  initialState,
  reducers: {
    //set leads
    handleLeadsStore: (state, action: PayloadAction<Lead>) => {
      state.lead = action.payload;
    },
    LeadsStoreEmpty: (state) => {
      state.lead = {} as Lead; //era array
    },
    handleLeadId: (state, action: PayloadAction<string>) => {
      state.leadId = action.payload;
    },

    handleClose: (state) => {
      state.hasError = false;
    },

    //set initialInspection
    handleInitialInspection: (
      state,
      action: PayloadAction<InitialInspectionSchema>
    ) => {
      state.initialInspection = action.payload;
    },
    InitialInspectionEmpty: (state) => {
      state.initialInspection = null;
    },
    handleInitialInspectionPhotos: (
      state,
      action: PayloadAction<RecordsPhotographics>
    ) => {
      state.initialInspectionPhotos = action.payload;
    },

    //set checkingInitialInspection
    handleCheckingInitialInspection: (state, action: PayloadAction<object>) => {
      state.checkingInitialInspection = action.payload;
    },
    checkingEmpty: (state) => {
      state.checkingInitialInspection = [] as ViabilyCheckingSchema;
    },
    //set email
    handleEmailStore: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    //set frontierId
    handleFrontierId: (state, action: PayloadAction<string>) => {
      state.frontierId = action.payload;
    },
    //set All materials of initial inspections
    handleTotalMaMO: (state, action: PayloadAction<ItemAddMaterial>) => {
      state.totalMaMo = action.payload;
    },
    handleTotalMaMOEmpty: (state) => {
      state.totalMaMo = {} as ItemAddMaterial;
    },
    //set the diagnostic emited
    handleDiagnosticIssued: (state, action: PayloadAction<CommentsState>) => {
      state.diagnosisIssued = action.payload;
    },
    handleDiagnosticIssuedEmpty: (state) => {
      state.diagnosisIssued = {} as CommentsState;
    },
    handleUrlPathsRender: (state, action: PayloadAction<UrlPathsOfRender>) => {
      state.urlPathsRender = action.payload;
    },
    handleUrlPathsRenderEmpty: (state) => {
      state.urlPathsRender = {} as UrlPathsOfRender;
    },

    handleOperatorData: (state, { payload }: PayloadAction<any>) => {
      state.emitedBy = {
        name: payload.username,
        email: payload.username,
        operator_id: payload.id,
      };
    },
    setDocuments(state, { payload }) {
      state.documents = payload;
    },
    resetDiagnostic: () => {
      return initialState;
    },
  },
  extraReducers: {
    //--------------getFrontier----------//

    [getForntierIdByLead.pending.type]: (state: diagnosticState) => {
      // state.isRequestingSignIn = true;
    },
    [getForntierIdByLead.fulfilled.type]: (
      state: diagnosticState,
      { payload }: PayloadAction<string>
    ) => {
      state.frontierId = payload;
    },
    //--------------getInitialinspection-------------------//

    [getOutputData.pending.type]: (state: diagnosticState) => {
      // state.isRequestingSignIn = true;
      state.isLoading = true;
      state.hasError = false;
    },

    [getOutputData.fulfilled.type]: (
      state: diagnosticState,
      { payload }: PayloadAction<any>
    ) => {
      if (payload.field !== null) {
        state.initialInspection = payload?.field?.input_data;
        state.checkingInitialInspection = payload?.field?.measurement_system;
        state.fieldServiceCertificationId = payload?.field?.input_data?.id;
      } else {
        state.desktop = payload?.desktop?.input_data;
      }
      if (payload.previus_diagnostic) {
        state.previousDiagnostic =
          payload?.previus_diagnostic?.form_data?.diagnostic;
        state.responsiblePreviousDiagnostic = {
          name: payload?.previus_diagnostic?.form_data?.emited_by?.name || null,
          createdAt: payload?.previus_diagnostic?.created_at,
        };
      } else {
        state.previousDiagnostic = null;
      }
      state.isLoading = false;
    },

    [getOutputData.rejected.type]: (state: diagnosticState, { payload }) => {
      if (payload?.response?.data?.detail === "E01") {
        state.errorMessage = "No cuenta con inspección previa";
      } else if (payload?.response?.data?.detail === "E02") {
        state.errorMessage =
          "No se cuenta con Trazabilidad de inspección previa / Registro fotográfico";
      } else if (payload?.response?.data?.detail === "E03") {
        state.errorMessage = "No cuenta con Factura del cliente";
      } else {
        state.errorMessage = "";
      }
      state.isLoading = false;
      state.hasError = true;
      if (state.initialInspection) {
        state.initialInspection = {} as InitialInspectionSchema;
        state.checkingInitialInspection = [] as ViabilyCheckingSchema;
        state.fieldServiceCertificationId = "";
      } else {
        state.desktop = null;
      }
      if (state.previousDiagnostic) {
        state.previousDiagnostic = null;
        state.responsiblePreviousDiagnostic = {} as Responsible;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  handleTotalMaMOEmpty,
  handleDiagnosticIssuedEmpty,
  LeadsStoreEmpty,
  InitialInspectionEmpty,
  checkingEmpty,
  handleEmailStore,
  handleLeadsStore,
  handleLeadId,
  handleInitialInspection,
  handleFrontierId,
  handleCheckingInitialInspection,
  handleInitialInspectionPhotos,
  handleTotalMaMO,
  handleDiagnosticIssued,
  handleUrlPathsRender,
  handleUrlPathsRenderEmpty,
  handleOperatorData,
  setDocuments,
  handleClose,
  resetDiagnostic,
} = diagnosticSlice.actions;

export const infoLeads = (state: StateStorage) => state.leads.lead;
export const inspection = (state: StateStorage) =>
  state.leads.initialInspection;
export const validationInspection = (state: StateStorage) =>
  state.leads.checkingInitialInspection;
export const multimediaRecords = (state: StateStorage) =>
  state.leads.initialInspectionPhotos;
export const materialsMmMO = (state: StateStorage) => state.leads.totalMaMo;
export const diagnosticIssued = (state: StateStorage) =>
  state.leads.diagnosisIssued;
export const pathsRender = (state: StateStorage) => state.leads.urlPathsRender;
export const leadId = (state: StateStorage) => state.leads.leadId;
export const fieldServiceCertificationId = (state: StateStorage) =>
  state.leads.fieldServiceCertificationId;
export const desktop = (state: StateStorage) => state.leads.desktop;
export const isLoading = (state: StateStorage) => state.leads.isLoading;
export const hasError = (state: StateStorage) => state.leads.hasError;
export const errorMessage = (state: StateStorage) => state.leads.errorMessage;
export const responsiblePreviousDiagnostic = (state: StateStorage) =>
  state.leads.responsiblePreviousDiagnostic;
export const previousDiagnostic = (state: StateStorage) =>
  state.leads.previousDiagnostic;

export default diagnosticSlice.reducer;
