/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import * as singleSpa from "single-spa";

export const DeviceVerification = () => {
  const mountedAppSingleSpa = () => {
    singleSpa.registerApplication(
      "app-device-verification",

      (): Promise<any> =>
        System.import("@enerBit/device-verification").catch(() =>
          console.log("error")
        ),

      (location) => location.pathname.startsWith("/")
    );
  };

  const appNamesAccess = singleSpa
    .getAppNames()
    .find((item) => item == "app-device-verification");

  useEffect(() => {
    if (appNamesAccess) {
      singleSpa
        .unregisterApplication("app-device-verification")
        .then(() => mountedAppSingleSpa());
    } else {
      mountedAppSingleSpa();
    }
  }, []);

  return <div id="single-spa-application:app-device-verification"></div>;
};
