/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getDiagnosticByFrontierId, getLead } from "./thunks";
import {
  DownloadDiagnosticStore,
  PayloadDiagnostic,
  Predios,
} from "../../models/downloadsDiagnostic";
// import { getPendingDiagnostic } from "./thunks";

const initialState: DownloadDiagnosticStore = {
  isLoading: false,
  predios: [] as Predios[],
  leadInformation: [] as any,
  diagnostics: null as any,
  textAlert: "",
  severityAlert: "",
};

// @ts-ignore
export const downloadSlice = createSlice({
  name: "download",
  initialState,
  reducers: {
    resetDownload: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getLead.pending.type]: (state) => {
      state.textAlert = "";
      state.severityAlert = "";
      state.isLoading = true;
    },

    [getLead.fulfilled.type]: (
      state: DownloadDiagnosticStore,
      { payload }: PayloadAction<any>
    ) => {
      const listLead = payload;
      const listAddress = [] as Predios[];
      state.predios = listLead?.map((item: { address: string; id: string }) =>
        listAddress.push({
          text: item.address,
          value: item.id,
        })
      );
      state.predios = listAddress;
      state.leadInformation = payload.items;
      state.isLoading = false;
    },
    //@ts-ignore
    [getLead.rejected]: (state) => {
      state.textAlert = "El correo ingresado no existe";
      state.severityAlert = "error";
      state.isLoading = false;
    },

    // /-----------GetAllDiagnostics--------------/

    [getDiagnosticByFrontierId.pending.type]: (state) => {
      state.textAlert = "";
      state.severityAlert = "";
      state.isLoading = true;
      // state.isRequestingSignIn = true;
    },

    [getDiagnosticByFrontierId.fulfilled.type]: (
      state: DownloadDiagnosticStore,
      { payload }: PayloadAction<PayloadDiagnostic>
    ) => {
      state.diagnostics = payload;
      state.isLoading = false;
    },

    [getDiagnosticByFrontierId.rejected.type]: (state) => {
      state.textAlert = "Ha ocurrido un error";
      state.severityAlert = "error";
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { resetDownload } = downloadSlice.actions;
export const isLoading = (state: DownloadDiagnosticStore) => state.isLoading;
export const leadInformation = (state: DownloadDiagnosticStore) =>
  state.leadInformation;
export default downloadSlice.reducer;
