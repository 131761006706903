import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Lead } from "../../models/diagnostic/diagnosticSearch";
import { ITableAddress } from "../../models/diagnostic/tableState";
import { getLeads } from "./thunk";

const initialState: ITableAddress = {
  listLeads: [],
  loading: false,
  page: 1,
  size: 5,
  rowCount: 1,
  selectedLeads: [],
  error: false,
  open: false,
  name: "",
};

const tableAddress = createSlice({
  name: "tableAddress",
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.size = action.payload;
    },
    handleLead(state, { payload }: PayloadAction<Lead>) {
      state.selectedLeads = [payload];
      state.page = 0;
    },
    handleLeads(state, { payload }: PayloadAction<Lead>) {
      const exists = !!state.selectedLeads.find(({ id }) => id === payload.id);
      state.selectedLeads = !exists
        ? [...state.selectedLeads, payload]
        : state.selectedLeads.filter(({ id }) => id !== payload.id);
    },
    setName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload;
    },
    openModal: (state) => {
      state.open = true;
    },
    closeModal: (state) => {
      state.open = false;
      resetTableAddress();
    },
    resetTableAddress: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLeads.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getLeads.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) {
        state.open = true;
        state.listLeads = payload.items;
        state.rowCount = payload.total;
      }
    });
    builder.addCase(getLeads.rejected, (state) => {
      state.loading = false;
      state.error = true;
      if (!state.name) {
        state.open = false;
      }
    });
  },
});

export const {
  setPage,
  setPageSize,
  handleLead,
  handleLeads,
  resetTableAddress,
  openModal,
  closeModal,
  setName,
} = tableAddress.actions;

export default tableAddress.reducer;
