/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import * as singleSpa from "single-spa";

export const Inconsistencies = () => {
	const mountedAppSingleSpa = () => {
		singleSpa.registerApplication(
			"app-inconsistencies",
			(): Promise<any> =>
				System.import("@enerBit/inconsistencies").catch(() =>
					console.log("error"),
				),

			(location) => location.pathname.startsWith("/"),
		);
	};

	const appNamesAccess = singleSpa
		.getAppNames()
		.find((item) => item === "app-inconsistencies");

	useEffect(() => {
		if (appNamesAccess) {
			singleSpa
				.unregisterApplication("app-inconsistencies")
				.then(() => mountedAppSingleSpa());
		} else {
			mountedAppSingleSpa();
		}
	}, []);

	return <div id="single-spa-application:app-inconsistencies"></div>;
};
