/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createAsyncThunk } from "@reduxjs/toolkit";
// @ts-ignore
import { api } from "@enerbit/base";
import { StateStorage } from "../../models/diagnostic/diagnosticStorage";
import {
  getBornersCompliance,
  getCellsCompilance,
  getTCCalibration,
  getTCCompliances,
  getTTCalibration,
  getTTCompliances,
  getWiresCompliances,
} from "../../services/diagnostics";
import { setDocuments } from "./diagnosticComplianceSlice";
import { getMaterialsAndLabor } from "../../helpers/diagnostic/services/materials";
import { Lead } from "../../models/diagnostic/diagnosticSearch";
import { getCertificateID } from "../../helpers/getCertificatesID";

export const getLeads = createAsyncThunk(
  //action type string
  "leads/getLeads",
  // callback function
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    const email = state["leads"]["email"];
    /* `let dataLeads = [] as Lead[];` está inicializando una matriz vacía `dataLeads` con una
    afirmación de tipo de `Lead[]`. Esto significa que se espera que la matriz contenga elementos de
    tipo `Lead`. Esta es una forma de especificar explícitamente el tipo de una matriz vacía en
    TypeScript. */
    let dataLeads = [] as Lead[];
    const params = {
      page: 0,
      size: 100,
    };
    try {
      let response = await api.get(`/leads/?email=${email}`, { params });
      let data = response.data;
      dataLeads = [...data.items];

      while (data.next_page) {
        params.page = data.next_page;
        response = await api.get(`/leads/?email=${email}`, { params });
        data = response.data;
        // Procesar los datos de la siguiente página aquí
        dataLeads.push(...data.items);
      }

      return dataLeads;
    } catch (error: any) {
      return 404;
    }
  }
);

export const getInitialInspection = createAsyncThunk(
  //action type string
  "leads/getInitialInspection",
  // callback function
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    const leadId = state["leads"]["leadId"];
    const certificate_type_id = await getCertificateID("PVINSP");
    const { data } = await api.get(
      `service-orders-certificates/certificates?assignee_id=${leadId}&certificate_type_id=${certificate_type_id}`
    );
    return data;
  }
);

export const getCheckInitialInspection = createAsyncThunk(
  //action type string
  "leads/geCheckInitialInspection",
  // callback function
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    const frontierId = state["leads"]["frontierId"];
    const { data } = await api.get(
      `/initial-inspection-viability-checking/inspections-check/?id=${frontierId}`
    );
    return data;
  }
);

export const getInitialInspectionsPhotos = createAsyncThunk(
  //action type string
  "leads/getInitialInspectionsPhotos",
  // callback function
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    const field_service_certification_id =
      state["leads"]["fieldServiceCertificationId"];
    try {
      const { data } = await api.get(
        `/initial-inspection-viability-checking/photographics-authentications/${field_service_certification_id}`
      );
      return data;
    } catch (error) {
      return 404;
    }
  }
);

export const postDiagnosticFile = createAsyncThunk(
  //action type string
  "leads/postDiagnosticFile",
  // callback function
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;

    // const frontierId = state["leads"]["initialInspection"];
    const frontierId = state["leads"]["frontierId"];
    // const lead = state["leads"]["lead"];
    const totalMaMo = state["leads"]["totalMaMo"];
    const diagnosisIssued = state["leads"]["diagnosisIssued"];
    const idInspection = state["leads"]["initialInspection"]?.["id"];
    const emitedBy = state["leads"]["emitedBy"];

    const viabilityEmited = {
      frontier_id: frontierId,
      file_path: "",
      form_data: {
        materials_and_labor: totalMaMo,
        // lead_information: getLeadInformation(lead),
        diagnostic: getMaterialsAndLabor(diagnosisIssued),
        emited_by: emitedBy,
        field_service_certification_id: idInspection,
      },
    };
    try {
      const { data } = await api.post(
        `/frontier-initial-diagnostic/emit-diagnostic`,
        viabilityEmited
      );
      return data;
    } catch (error) {
      return 404;
    }
  }
);

export const getDocument = createAsyncThunk(
  //action type string
  "leads/getDocument",
  // callback function
  async (document_id: string) => {
    try {
      const { data } = await api.get(
        `/documentation/viability-documents/${document_id}`
      );
      return data;
    } catch (error) {
      return 404;
    }
  }
);

export const authDocument = createAsyncThunk(
  //action type string
  "leads/getAutDocument",
  // callback function
  async (file_path: string) => {
    try {
      const json = { url: file_path };
      const { data } = await api.post(
        `/documentation/authenticate-documents`,
        json
      );
      return data;
    } catch (error) {
      return 404;
    }
  }
);

export const getMaterials = createAsyncThunk(
  //action type string
  "leads/getLeads",
  // callback function
  async () => {
    try {
      const { data } = await api.get(`/inventory/inventories/`);
      return data;
    } catch (error) {
      return 404;
    }
  }
);

export const getForntierIdByLead = createAsyncThunk(
  //action type string
  "leads/getFrontierId",
  // callback function
  async (leadId: string): Promise<string | number> => {
    // console.log(leadId);
    try {
      const { data } = await api.get(
        `/assignees/frontiers?lead_id=${leadId}&page=0&size=50`
      );
      const result = data.items[0]?.id;
      return result;
    } catch (error) {
      return 404;
    }
  }
);

export const getOutputData = createAsyncThunk(
  //action type string
  "leads/getOutputData",
  // callback function
  async (diagnosticType: string, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState() as StateStorage;
      const id = state["leads"]["leadId"];

      const { data } = await api.get(
        `/frontier-initial-diagnostic/output-data?lead_id=${id}&is_field=${diagnosticType}`
      );

      let docs: any = [];

      if (data.field !== null) {
        const measurementType =
          data.field.input_data.form_data.main_meter.measurement_type;
        if (measurementType === "indirecta") {
          const wiresCompliances = await getWiresCompliances(data);
          const bornersCompliance = await getBornersCompliance(data);
          const ttCompliance = await getTTCompliances(data);
          const tcCompliance = await getTCCompliances(data);
          const ttCalibrations = await getTTCalibration(data);
          const tcCalibrations = await getTCCalibration(data);
          const cellsCompilance = await getCellsCompilance(data);

          docs = [
            ...docs,
            ...wiresCompliances,
            ...bornersCompliance,
            ...ttCompliance,
            ...tcCompliance,
            ...ttCalibrations,
            ...tcCalibrations,
            ...cellsCompilance,
          ];
        } else if (measurementType === "semidirecta") {
          const wiresCompliances = await getWiresCompliances(data);
          const bornersCompliance = await getBornersCompliance(data);
          const tcCompliance = await getTCCompliances(data);
          const tcCalibrations = await getTCCalibration(data);
          const cellsCompilance = await getCellsCompilance(data);

          docs = [
            ...docs,
            ...wiresCompliances,
            ...bornersCompliance,
            ...tcCompliance,
            ...tcCalibrations,
            ...cellsCompilance,
          ];
        } else {
          const cellsCompilance = await getCellsCompilance(data);
          docs = [...docs, ...cellsCompilance];
        }
      }
      dispatch(setDocuments(docs));
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
