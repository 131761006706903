/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getLeads = createAsyncThunk(
  "tableAddress/getLeads",
  async (
    {
      email,
      page = 1,
      size = 5,
      name = "",
    }: {
      email: string;
      page?: number;
      size?: number;
      name?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const parsedEmail = encodeURIComponent(email);
      const { data } = await api.get(
        `/leads/?email=${parsedEmail}&page=${page}&size=${size}${
          name ? `&contact_name=${name}` : ""
        }`
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data);
    }
  }
);
