//@ts-ignore
import { Box, NavSidebar, OptionsList } from "@enerbit/base";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { PendingDiagnostic } from "../PendingDiagnostics/PendingDiagnostic";
import { DeviceVerification } from "../deviceVerification";
import DiagnosticCompliancePage from "../diagnosticCompliance/DiagnosticCompliancePage";
import DownloadDiagnostic from "../downloadDiagnostic/DownloadDiagnostic";
import { Inconsistencies } from "../inconsistencies/Inconsistencies";
import { Reports } from "../records/Records";

const TELEMETRY_POSITION = 8;

const RecordsContainer = () => {
	const [positionOption, setPositionOption] = useState(
		parseInt(localStorage.getItem("recordsPosition")!) || 2,
	);

	const dataBoton = [
		{
			name: "Gestión campo",
			position: 0,
			component: null as any,
			isTitle: true,
		},
		{
			name: "Reportes",
			position: 1,
			isSubtitle: true,
		},
		{
			name: "Reportes",
			position: 2,
			component: <Reports />,
			isTitle: false,
		},
		{ name: "Herramientas de verificación", position: 3, isSubtitle: true },
		{
			name: "Telemetría y prealistamiento",
			position: 4,
			isTitle: false,
			component: <DeviceVerification />,
		},
		{ name: "Inconsistencias", position: 5, isSubtitle: true },
		{
			name: "Inconsistencias",
			position: 6,
			isTitle: false,
			component: <Inconsistencies />,
		},
	];

	useEffect(() => {
		localStorage.setItem("recordsPosition", `${positionOption}`);
	}, [positionOption]);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				height: "100vh",
				width: "100vw",
				overflow: "hidden",
			}}
		>
			<NavSidebar />
			<Box
				sx={{
					display: "flex",
					position: "relative",
					flexGrow: 1,
					paddingTop: "70px",
					overflow: "auto",
				}}
			>
				<OptionsList
					sx={{ fontSize: "28px" }}
					arrayOptions={dataBoton}
					positionOption={positionOption}
					setPositionOption={setPositionOption}
				/>
				<div
					style={{
						zIndex: "0",
						width: "100%",
						overflowY: "scroll",
						backgroundColor:
							positionOption === TELEMETRY_POSITION ? "#F2F4F7" : undefined,
					}}
				>
					{dataBoton[positionOption].component}
				</div>
			</Box>
		</div>
	);
};

export default RecordsContainer;
