/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import * as singleSpa from "single-spa";

export const Reports = () => {
  const mountedAppSingleSpa = () => {
    singleSpa.registerApplication(
      "app-reports",
      (): Promise<any> =>
        System.import("@enerBit/reports").catch(() => console.log("error")),

      (location) => location.pathname.startsWith("/")
    );
  };

  const appNamesAccess = singleSpa
    .getAppNames()
    .find((item) => item == "app-reports");

  useEffect(() => {
    if (appNamesAccess) {
      singleSpa
        .unregisterApplication("app-reports")
        .then(() => mountedAppSingleSpa());
    } else {
      mountedAppSingleSpa();
    }
  }, []);

  return <div id="single-spa-application:app-reports"></div>;
};
